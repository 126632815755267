<template>
    <div
    >
    <b-card no-body>
        <div class="m-2">
            
            <vue-good-table
            :columns.sync="tableColumns"
            :rows.sync="Data"
            ref="refListTable"
            styleClass="vgt-table condensed"
            
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
            <span 
            v-if="props.column.field == 'is_active'"
            class="text-nowrap"
            >
                <b-badge
                    pill
                    :variant="`light-${resolveUserStatusVariant(props.row.is_active)}`"
                    class="text-capitalize"
                >
                    {{ resolveUserStatusnameVariant(props.row.is_active) }} 
                </b-badge>
            </span>
            <div v-else-if="props.column.field === 'status'">
                            
                            <span :class="props.row.status*1==3?'text-danger':''">{{  resolveStatusTextVariant(props.row.status) }}</span>

          </div>
            <span v-else-if="props.column.field == 'course_balance'">
              <template v-if="props.row.product_count > 0"> {{  props.row.product_count }} unit</template>
              <template v-else> {{  props.row.balance }}</template>
             


            </span>
            <span
            v-else
            class="text-nowrap"
            >
            {{props.formattedRow[props.column.field]}}
            </span>
                <span
                v-if="props.column.field === 'action'"
                class="text-nowrap"
                >


                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="updateData(props.row)"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,props.row.title,props.row.option)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>

                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
            
        </div>

    </b-card>
</div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useDetailList from './useDetailList'
import patientsStoreModule from '../patientsStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import GroupForm from './GroupForm.vue'
import vSelect from 'vue-select'
import router from '@/router'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,
        VueGoodTable,
        vSelect,
        GroupForm,

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    props: {
        bar_option:{
        type: String,
        default: 'Receipt',
      }
    },
    setup(props,{ emit }) {
        const HeaderText = ref('')
        const isGroupFormSidebarActive = ref(false)
        const GroupData = ref({})
        const id = ref(router.currentRoute.params.id);
        const options_status = ref([
            { value:'1',text:'Active' },
            { value:'0',text:'Inactive' },
        ]);
        const PATIENTS_STORE_MODULE_NAME = 'app-patients-group';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            selected_status,
            currentPage,
            refListTable,
            patient_id,
            OptionFetch,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            resolveStatusTextVariant,
        resolveStatusVariant
        } = useDetailList();


        const addGroup = (data)=>{

        GroupData.value = {};
        HeaderText.value = 'เพิ่มแท๊ก';
        isGroupFormSidebarActive.value = true;
        }


        const updateData = (data)=>{
        data.is_active = false?data.is_active==0:true?data.is_active==1:false;
        GroupData.value = data;
        HeaderText.value = 'แก้ไขแท๊ก';
        isGroupFormSidebarActive.value = true;
        }

        return {
            options_status,
            tableColumns,
            fetchData,
            deleteData,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            selected_status,
            currentPage,
            refListTable,
            OptionFetch,
            GroupData,
            patient_id,
            addGroup,
            updateData,
            isGroupFormSidebarActive,
            HeaderText,
            useDetailList,
            resolveStatusTextVariant,
        resolveStatusVariant
        }

        
    },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Group TITLE: '+title, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.deleteData(id)
          }
        })

        },
    },
    watch: {
        bar_option:function () {
            this.OptionFetch = this.bar_option;
           
            this.fetchData();
        }
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>