<template>
    <b-sidebar
      id="voucher-form-sidebar"
      :visible="isVoucherFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(id)"
    
      @change="(val) => $emit('update:is-voucher-form-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    {{  t('voucher') }}
                </h5>
        
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
        
          </div>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              class="p-2 form-boder-input"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
            <b-row>
              <b-col md="6">
                <validation-provider
              #default="validationContext"
              name="checklist"
             
            >
              <b-form-group
                label="ประเภท"
                label-for="checklist"
              >
              <v-select
                :options="category_Data"
                v-model="Data.category_id"
                :get-option-label="(option) => option.title"
                :reduce="val => val.id"
                label="title"
                placeholder="กรุณาเลือกประเภท"
                @input="ChangeCategory($event)"
                               
                
                >
               
              </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              
            </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                            label="วันที่หมดอายะุ"
                            label-for="expire_date"
                            >
                            <b-form-datepicker
                                id="expire_date"
                                v-model="inputData.expire_date"
                                type="date"
                            />
                        </b-form-group>
              </b-col>
            </b-row>
           
            <div class="mt-2">
                <b-table
                ref="refvoucherListTable"
                responsive
                    :fields="tableColumns"
                   
                    :items="items"
                    show-empty
                    empty-text="No matching records found"
                   
                    class="position-relative"
                >
                <template #cell(title)="data">
                    {{ data.item.title }} <br />
                    {{ data.item.remark }}<br />
                    <small v-if="data.item.num_exp_date > 0" class="text-danger">***Voucher มีอายุการใช้งาน {{ data.item.num_exp_date  }}</small>
                  
                </template>
                <template #cell(credit)="data">
                  <b-form-input
                      
                        v-model="data.item.credit"
                       
                        
                      />
                    
                </template>
                <template #cell(qty)="data">
                      <b-form-spinbutton
                        id="sb-inline"
                        v-model="data.item.qty"
                        min="0"
                        inline
                      />
                </template>
                <template #cell(price)="data">
                    
                    {{  getComma(data.item.price) }}
                </template>
                
                </b-table>

              </div>
              <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Close
              </b-button>
            </div>
            </b-form>
          </validation-observer>
        </template>
    </b-sidebar>
</template>
<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable,
    BRow,BCol,BFormDatepicker
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,onUnmounted } from '@vue/composition-api'
  import { required, alphaNum, email, password,confirmed } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { useToast } from 'vue-toastification/composition'
  import useVoucherAdd from './useVoucherAdd'
  import { getComma } from '@core/utils/filter'


  import voucherStoreModule from '../../voucher/voucherStoreModule'
  import { BFormSpinbutton } from 'bootstrap-vue'
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BRow,BCol,BFormDatepicker,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BTable,
      BFormSpinbutton
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isVoucherFormSidebarActive',
      event: 'update:is-voucher-form-sidebar-active',
    },
    props: {
      isVoucherFormSidebarActive: {
        type: Boolean,
        required: true,
      },
      id:{
        type:Number
      },
     
      userData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        getComma
      }
    },
    setup(props, { emit }) {
      const toast = useToast()
     
      const { t } = useI18nUtils()

      const VOUCHER_STORE_MODULE_NAME = 'app-voucher';
      if (!store.hasModule(VOUCHER_STORE_MODULE_NAME)) store.registerModule(VOUCHER_STORE_MODULE_NAME, voucherStoreModule)
      onUnmounted(() => {
          if (store.hasModule(VOUCHER_STORE_MODULE_NAME)) store.unregisterModule(VOUCHER_STORE_MODULE_NAME)
      });    
      const {
        onSubmit,
        category_Data,
        Data,
        ChangeCategory,
        tableColumns,
        items,
        refvoucherListTable,
        fetchData,
        getData,
        inputData
      } = useVoucherAdd(emit);
      
      
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation()
  
      return {
        t,
        onSubmit,
        category_Data,
        Data,
        ChangeCategory,
        tableColumns,
        items,
        refFormObserver,
        getValidationState,
        resetForm,
        refvoucherListTable,
        fetchData,
        getData,
        inputData
        
        
      }
    },
  }
</script>