import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import medicine from '@/router/routes/medicine'
export default function useDetailList() {

  const OptionFetch = ref('medicine');
  const searchQuery = ref('');
  const selected_group = ref('');
  const selected_status = ref('');
  const patient_id = ref(router.currentRoute.params.id);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const Group_Total = ref(0);
  const Type_Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const fetch_option = ref('fetchGroupData')
  const tableColumns = ref([])

  
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const statuses = [
      {
        id:1,
        title:'รอรับการรักษา'
      },
      {
        id:2,
        title:'รับการรักษา'
      },
      {
        id:3,
        title:'ยกเลิก'
      }
    ]
    const refetchData = () => {
      refListTable.value.refresh()
  }

    watch([currentPage, pageLength, searchQuery, selected_status,patient_id], () => {
      fetchData();
    })
    const fetchData = async () =>{
        //1   ใบเสร็จ 
        //2   ยา/อุปกรณ์  
        //3   การตรวจ/แล็บ  
        //4   การใช้บริการ  
        //5   คอร์สคงเหลือ  
        //6   ใบรับรองแพทย์  
        //7   นัดหมาย 

        console.log('click',OptionFetch.value);
     
      if(OptionFetch.value == 'medicine'){
        fetch_option.value = 'fetchPatientMedicineData'
        tableColumns.value = [
          { field: 'receipt_no', label: 'เลขที่ใบเสร็จ', sortable: false },
            { field: 'item_name', label: 'รายการ', sortable: false },
            {field: 'qty', label: 'จำนวน', sortable: false},
            { field: 'unit', label: 'หน่วย', sortable: false },
        ]
    
      }
      else if(OptionFetch.value == 'vocher'){
        fetch_option.value = 'fetchPatientVoucherData'
        tableColumns.value = [
          { field: 'category_name', label: 'ประเภท', sortable: false},
            { field: 'balance', label: 'คงเหลือ', sortable: false },
          
        ]
      }
      else if(OptionFetch.value == 3){
        fetch_option.value = 'fetchServicesData'
        tableColumns.value = [
          { field: 'id', label: 'ไอดี', sortable: false, hidden:true },
            { field: 'service_no', label: 'เลขบริการ', sortable: false },
            { field: 'date', label: 'วันที่', sortable: false },
            { field: 'status', label: 'สถานะ', sortable: false },
        
        ]
      }
      else if(OptionFetch.value == 4){
        fetch_option.value = 'fetchServicesData'
        tableColumns.value = [
          { field: 'id', label: 'ไอดี', sortable: false, hidden:true },
            { field: 'service_no', label: 'เลขบริการ', sortable: false },
            { field: 'date', label: 'วันที่', sortable: false },
            { field: 'status', label: 'สถานะ', sortable: false },
         
        ]
      }
      else if(OptionFetch.value == 5){
        fetch_option.value = 'fetchPatientCourseData'
        tableColumns.value = [
            { field: 'title', label: 'ชื่อคอร์ส', sortable: false },
            { field: 'updated_at', label: 'ใช้ล่าสุด', sortable: false },
            { field: 'course_balance', label: 'คงเหลือ', sortable: false },
        ]
      }
      else if(OptionFetch.value == 6){
        fetch_option.value = 'fetchGroupData'
        tableColumns.value = [
          { field: 'id', label: 'ไอดี', sortable: false, hidden:true },
            { field: 'title', label: 'ชื่อ', sortable: false },
            { field: 'is_active', label: 'สถานะ', sortable: false },
            { field: 'action', label: 'การจัดการ', sortable: false },
        ]
      }
      else if(OptionFetch.value == 7){
        fetch_option.value = 'fetchPatientAppointmentData'
        tableColumns.value = [
          { field: 'checklist_title', label: 'หัวข้อ', sortable: false },
            { field: 'date', label: 'วันที่นัด', sortable: false },
            { field: 'time', label: 'เวลา', sortable: false },
            { field: 'doc_name', label: 'แพทย์ (ผู้นัด)', sortable: false },
            { field: 'is_active', label: 'สถานะ', sortable: false },
        ]
      }

      const offset = pageLength.value*(currentPage.value-1);
      Data.value  = [];
      await store
            .dispatch(`app-patients-group/${fetch_option.value}`, {
              limit: pageLength.value,
              offset:offset,
              keywords: searchQuery.value,
              branch_id:branch_id,
              patient_id:patient_id.value,
              group_id:selected_group.value,
              is_active:selected_status.value,
              order_by:'id DESC'
          
            })
            .then(response => {
              Data.value = response.data.data;
              console.log('Data.value',Data.value);
              console.log(fetch_option.value,Data.value);
              const total = response.data.total?response.data.total: Data.value.length;
              Group_Total.value = total;
            })
            .catch((error) => {
              console.log('error',error);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching patients group list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    }

    fetchData();
    const deleteData = (id) => {
      //fetchDeleteProperty
      var data = {id:id}
      var res = store.dispatch('app-patients-group/deleteGroupData',data)

      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }



    const resolveUserStatusVariant = status => {
    
      if (status == 2) return 'warning'
      if (status == 1) return 'success'
      if (!status) return 'danger'
      return 'primary'
    }
    const resolveUserStatusnameVariant = status => {
  
        if (status == 2) return 'รอดำเนินการ'
        if (status == 1) return 'เสร็จสิ้น'
        if (!status) return 'ยกเลิก'
        return 'primary'
    }

    const resolveStatusVariant = (status,types) =>{

      if(types == 'print'){
        if(status == 2){
          return 'primary'

        }else{
          return 'mute'
        }

      }
      if(types == 'check'){
        if(status == 3){
          return 'mute'
        }else{
          return 'primary'
        }
      }
      
        return 'primary'
    }

    const resolveStatusTextVariant = status =>{
    const index =  statuses.findIndex(s => s.id === status)
    let text = 'รอรับการรักษา'
      if(index > -1){
        text = statuses[index].title;
      }
     
        return text
    }

    return {
        tableColumns,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_status,
        patient_id,
        OptionFetch,
        currentPage,
        refListTable,
        deleteData,
        fetchData,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveStatusTextVariant,
        resolveStatusVariant
    }
}