import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useCourseAdd(emit) {
    const t = useI18nUtils;
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const blankData = {
      
        category_id:''
     
    }
    const toast = useToast()
    const Data = ref(JSON.parse(JSON.stringify(blankData)));
    const cats = ref([]);
    const courses = ref([]);
    const refcourseListTable = ref();
    const items = ref([]);
    const tableColumns = [
      
      { key: 'no', label: '#', sortable: false },
      { key: 'title', label: t('list_service'), sortable: false },
     
      
      { key: 'qty', label: 'จำนวน', sortable: false },
      { key: 'price', label: 'ราคา/หน่วย', sortable: false },
    


  ];
  const inputData = ref({
    patient_id:'',
    expire_date:'',
    courses:[]
  })
    const getData = (id)=>{
        console.log('getData', id);
        

    }
    const ChangeCategory = (event)=>{
        // refvoucherListTable.value.refresh()
        courses.value  = [];
        
        getCourses();
       
        
   
    }
    const getCoursesCategory = async ()=>{
        const categories = await store.dispatch('app-course/fetchCategoryData',{
            /*limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            category_id:selected_category.value,
            type_id:selected_type.value,*/
            is_active:1,
          //  branch_id:branch_id,
         
        });
        if(categories.data.success){
            cats.value = categories.data.data;

        }
        console.log('categories',cats.value);
        
    }
    getCoursesCategory();
    const getCourses = async (patient_id)=>{
        inputData.value.patient_id = patient_id
        //fetchCourseData
       const course = await store.dispatch('app-course/fetchData',{
            /*limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            
            type_id:selected_type.value,*/
          
            is_active:1,
            //branch_id:branch_id,
            
           });
           if(course.data.success){
                courses.value = course.data.data;
                if(courses.value.length > 0){
                    courses.value.map((itm)=>{
                      itm.qty = 0;
                      return itm;
      
                    });
      
                  }

            }
           console.log('course',course.data);
    }
   
    const onSubmit = async () => {
       // inputData.value.courses = courses;
       
       courses.value= courses.value.filter((value)=>{
           if(value.qty > 0){
               
                return true;

           }
            
return false;
          

      });
      inputData.value.courses = courses.value.map((value)=>{
                if(value.qty > 0){
                    let itm = {
                        course_id:value.id,
                
                        qty:value.qty,
                        created_by:userStorage.id,
                        expire_day:value.expire_day,
                        patient_id:inputData.value.patient_id,
                        expire_date:inputData.value.expire_date?inputData.value.expire_date:null,
                    
                
                    }
                    
                    return itm;

                }
                

            

        });
      let response = await store.dispatch('app-course/addCoursesPatient',inputData.value);
      if(response.data.success){
        toast({
            component: ToastificationContent,
            props: {
              title: "Add Course Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }else{
        toast({
            component: ToastificationContent,
            props: {
              title: "Error add Course",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      }
      console.log('inputData-2',inputData);
       /*
        let itms = courses.value.map((value)=>{
           
              let itm = {
                  id:value.id,
                  types:'course',
                  item_name: value.title,
                  description:'',
                  qty:value.qty,
                  price:value.price,
                  discount:0,
                  expire_day:value.expire_day,
                  total:value.price*value.qty
          
              }
              return itm;
  
            
  
        });*/
        //console.log('items',itms);
  
     
        emit('refetch-data')
        emit('update:is-course-add-sidebar-active', false)
        
      }

    return {
        getData,
        cats,
        courses,
        tableColumns,
        refcourseListTable,
        items,
        Data,
        ChangeCategory,
        getCourses,
        onSubmit,
        inputData
    }
}