<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-table
                    ref="refreceiptListTable"
                    responsive
                    :fields="tableColumns"
                   
                    :items="fetchData"
                    show-empty
                    empty-text="No matching records found"
                   
                    class="position-relative"
                >
                <template #cell(receipt_no)="data">
                    <b-link
                       
                        :to="{ name: 'receipt-confirm', params:{id:data.item.id}}"
                    >
                        #{{ data.item.receipt_no }}
                    </b-link>
                   
                </template>
                <template #cell(start_date)="data">
                    {{ formatDateTh(data.item.start_date)   }}
                </template>
                <template #cell(price_total)="data">
                    {{ getCommaDecimal(data.item.price_total)   }}
                </template>
                
                
              
       

                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                        </b-col>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totals"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                    </template>
                                    <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
///import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateTh,getCommaDecimal } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
import receiptStoreModule from '../receiptStoreModule'
import useReceiptPatient from './useReceiptPatient'
//import html2pdf from 'html2pdf.js'
import VueHtml2pdf from 'vue-html2pdf'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,
    VueGoodTable

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            getCommaDecimal
        }
    },
    setup(props, { emit }){
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
        if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
        onUnmounted(() => {
            if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
        }); 
        const {
            t,
            patients_id,
            tableColumns,
            refreceiptListTable,
            fetchData,
            currentPage,
            totals,
            perPage,
            dataMeta

        } = useReceiptPatient();

        return {
            t,
            patients_id,
            tableColumns,
            refreceiptListTable,
            fetchData,
            currentPage,
            totals,
            perPage,
            dataMeta

        }

    }
    
}
</script></script>