<template>
    <div>
        <course-add 
            :is-course-add-sidebar-active.sync="isCourseAddSidebarActive"
            :id="id"
          
            @refetch-data="refetchData" 
        />
        <voucher-add 
            :is-voucher-form-sidebar-active.sync="isVoucherFormSidebarActive"
            :id="id"
          
            @refetch-data="refetchData" 
        />
   
    <b-row>
        <b-col lg="5">
            <b-card no-body>
                <patients-card/>
            </b-card>

        </b-col>
        <b-col lg="7">
            <b-card no-body>
               
               <div class="d-flex my-1 ml-3">
           <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm"
               :to="{ name: 'opd-detail', params:{id:id}}"
            
            >
            เพิ่ม OPD
            </b-button>
            <!--<b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
               :to="{ name: 'patients-detail', params:{id:id}}"
            
            >
            เพิ่มคิวตรวจ
            </b-button>-->
            <!--<b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
               :to="{ name: 'patients-detail', params:{id:id}}"
            
            >
            เพิ่มคิวใช้บริการ
            </b-button>-->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
               :to="{ name: 'receipt-create', params:{user_id:id}}"
            
            >
            เปิดใบเสร็จ
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
                @click="addCourse"
            
            >
            เพิ่มคอร์ส
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
              @click="addVoucher"
            
            
            >
            เพิ่มบัตรกำนัน
            </b-button>
            <!--<b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
               :to="{ name: 'patients-detail', params:{id:id}}"
            
            >
            รอ Confirm
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="info"
              class="btn btn-primary btn-sm ml-1"
               :to="{ name: 'receipt-create', params:{user_id:id}}"
            
            >
            ไม่พบแพทย์
            </b-button>-->
                </div>
               
            </b-card>
              <b-tabs small>
                <b-tab  title="ใบเสร็จ" active v-on:click="setNewValue('receipt')"></b-tab>
                <b-tab  title="ยา/อุปกรณ์" v-on:click="setNewValue('medicine')"></b-tab>
                <b-tab  title="การใช้บริการ" v-on:click="setNewValue(4)"></b-tab>
                <b-tab  title="คอร์สคงเหลือ" v-on:click="setNewValue(5)"></b-tab>
                <b-tab  title="บัตรกำนัน" v-on:click="setNewValue('vocher')"></b-tab>
                <b-tab  title="นัดหมาย" v-on:click="setNewValue(7)"></b-tab>
              </b-tabs>

            <b-card no-body>
              
                <detail-list :bar_option.sync="bar_option" v-if="bar_option != 'receipt'"/>
                
                <receipt-patient v-if="bar_option == 'receipt'" />
            </b-card>
        </b-col>
    </b-row>
</div>
</template>
<script>
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,
  BButton,BTabs,BTab
 } from 'bootstrap-vue'

import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import PatientsCard from './PatientsCard.vue'
import DetailList from './DetailList.vue' //detail about service usage information list
import CourseAdd from './CourseAdd.vue'
import ReceiptPatient from '../../receipt/receipt-list/ReceiptPatient.vue'
import patientsStoreModule from '../patientsStoreModule'
import VoucherAdd from './VoucherAdd.vue'

 import Ripple from 'vue-ripple-directive'
 import router from '@/router'
import { async } from 'q'
 export default {
    directives: {
        Ripple,
    },
    components:{
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,
        BButton,BTabs,BTab,
        PatientsCard,
        DetailList,
        ReceiptPatient,
        CourseAdd,
        VoucherAdd
    },

    setup({ emit }){
        //1   ใบเสร็จ 
        //2   ยา/อุปกรณ์  
        //3   การตรวจ/แล็บ  
        //4   การใช้บริการ  
        //5   คอร์สคงเหลือ  
        //6   ใบรับรองแพทย์  
        //7   นัดหมาย  
        const bar_option = ref('receipt');
        const isCourseAddSidebarActive = ref(false);
        const isVoucherFormSidebarActive = ref(false);
        
        const id = ref(router.currentRoute.params.id);

        function setNewValue(value){
           
          bar_option.value = value;
        }
        const refetchData = ()=>{

        }
        const addCourse = ()=>{
            isCourseAddSidebarActive.value = true;

        }
        const addVoucher = ()=>{
            isVoucherFormSidebarActive.value = true;

        }
        return {
            id,
            setNewValue,
            bar_option,
            isCourseAddSidebarActive,
            isVoucherFormSidebarActive,
            refetchData,
            addCourse,
            addVoucher
        }
        
    },
    methods:{
      
    }

 }
</script>